/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "../css/app.css";
// <!-- Jquery -->
// import './jquery.js';
//

const $ = require("jquery");
const jQuery = require("jquery");
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require("bootstrap");
// // Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
// import jQuery from 'jquery';
//
// <!-- Plugins JS-->
// import './plugins';

// <!-- Navbar JS -->
import "./navigation";
import "./navigation.fixed";

// <!-- Google Map -->
import "./map";

// <!-- lightbox2 -->
import "./lightbox/lightbox.min";

// <!-- Main JS -->
// import './main';

console.log("Hello Webpack Encore! Edit me in assets/js/app.js");

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        Headers.append("Service-Worker-Allowed", "/");
        navigator.serviceWorker.register('/sw.js',  { scope: "./" }).then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
